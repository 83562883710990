import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from './ThemeContext';
import GlobalStyle from './GlobalStyle';
import Post from './pages/Post';
import Index from './pages/Index';
import useBaiduAnalytics from './hooks/useBaiduAnalytics';

function App() {
  useBaiduAnalytics();

  return (
    <ThemeProvider>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/post/:id" element={<Post />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
