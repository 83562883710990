import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTheme } from '../ThemeContext';

const BeaudarContainer = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  border: 1.5px solid ${props => props.theme.lineColor};
  border-radius: 4px;
  padding: 12px;
  @media (max-width: 768px) {
    border: none;
    padding: 0;
  }
`;

const Beaudar = ({ title }) => {
  const theme = useTheme();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://beaudar.lipk.org/client.js';
    // script.src = 'https://utteranc.es/client.js';
    // script.src = 'https://4e8c8a19.beaudar.pages.dev/client.js';
    // script.src = 'http://localhost:3000/client.js';
    script.async = true;

    const attributes = {
      repo: 'hongjiev/javadoop-comments',
      branch: 'master',
      'issue-term': 'pathname',
    //   'issue-label': 'pathname',
      theme: theme.isDark ? 'github-dark' : 'github-light',
      'keep-theme': 'false',
      loading: 'false',
      'comment-order': 'desc',
      'input-position': 'top',
      crossorigin: 'anonymous',
    };

    Object.entries(attributes).forEach(([key, value]) => {
      script.setAttribute(key, value);
    });

    const comments = document.getElementById('beaudar-comments');
    if (comments) {
      comments.appendChild(script);
      
      // 手动初始化 Beaudar
      script.onload = () => {
        window.beaudar && window.beaudar.init();
      };
    }

    return () => {
      if (comments) comments.innerHTML = '';
    };
  }, [title, theme.isDark]);

  return <BeaudarContainer id="beaudar-comments"></BeaudarContainer>;
};

export default Beaudar;
