import React, { useEffect, useState, useMemo } from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { marked } from 'marked';
import CommentSystem from '../components/CommentSystem';
import Beaudar from '../components/Beaudar';
import { lightTheme, darkTheme } from '../theme';
import { useTheme } from '../ThemeContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// 复用 Post 组件中的一些样式
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const StyledContent = styled.div`
  flex: 1;
  padding: 20px 0;
  max-width: 960px;
  margin: 0 auto;
  font-size: 16px;
  @media (max-width: 768px) {
    padding: 12px 16px 0 16px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
`;

const CommentSectionDivider = styled.hr`
  margin: 40px 0;
  border: 0;
  border-top: 1px solid ${props => props.theme.lineColor};
`;

const Footer = styled.footer`
  text-align: center;
  margin-top: 40px;
  padding: 20px 0 10px;
  border-top: 1px solid ${props => props.theme.isDark ? '#4a4a4a' : '#eaecef'};
  color: var(--secondary-color);
  font-size: 14px;
`;

// 新的导航栏样式
const NavBar = styled.nav`
  height: 60px;
  width: 100%;
  background-color: #2f4258;
  display: flex;
  align-items: center;
`;

const NavContent = styled.div`
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
`;

const NavTitle = styled.span`
  color: white;
  font-size: 24px;
  font-weight: bold;
`;

const NavTitleLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
`;

function Index() {
  const { isDark, toggleTheme } = useTheme();

  useEffect(() => {
    if (isDark) {
      toggleTheme();
    }
  }, []);

  const [post, setPost] = useState(null);
  const [comments, setComments] = useState([]);
  const [authorInfo, setAuthorInfo] = useState('HongJie');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/post/get-post-content?name=index`);
        if (!response.ok) {
          throw new Error('网络响应不正常');
        }
        const postData = await response.json();
        if (postData && postData.content) {
          setPost(postData);
          document.title = postData.title;
        } else {
          console.error('API 响应中缺少必要的字段');
          setPost(null);
        }
      } catch (error) {
        console.error('获取文章内容时出错:', error);
        setPost(null);
      } finally {
        setIsLoading(false); 
      }
    };

    const fetchComments = async () => {
      try {
        console.log('Fetching comments...');
        const response = await fetch(`${API_BASE_URL}/comment/get-comment?name=index`);
        console.log('Response status:', response.status);
        if (!response.ok) {
          throw new Error('网络响应不正常');
        }
        const commentsData = await response.json();
        console.log('Fetched comments data:', commentsData);
        setComments(commentsData.data);
      } catch (error) {
        console.error('获取评论时出错:', error);
        setComments([]);
      }
    };

    fetchPost();
    fetchComments();
  }, []);

  const handleDoubleClick = () => {
    setAuthorInfo(authorInfo === 'HongJie' ? 'HongJie (hongjie.v@gmail.com)' : 'HongJie');
  };

  const renderedContent = useMemo(() => {
    if (!post) return null;
    return marked(post.content);
  }, [post]);

  if (isLoading) {
    return (
      <PageWrapper>
        <NavBar>
          <NavContent>
            <NavTitle>Javadoop</NavTitle>
          </NavContent>
        </NavBar>
        <StyledContent>
          <ContentContainer>
            加载中...  
          </ContentContainer>
        </StyledContent>
      </PageWrapper>
    );
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <PageWrapper>
        <NavBar>
          <NavContent>
            <NavTitleLink href="/">
              <NavTitle>Javadoop</NavTitle>
            </NavTitleLink>
          </NavContent>
        </NavBar>
        <StyledContent>
          <ContentContainer>
            {renderedContent && <div dangerouslySetInnerHTML={{ __html: renderedContent }} />}
            {!isLoading && (
              <>
                <CommentSectionDivider />
                <CommentSystem comments={comments} />
                <Beaudar title="Javadoop 首页" />
              </>
            )}
          </ContentContainer>
        </StyledContent>
        <Footer>© Javadoop {new Date().getFullYear()}, Created by{' '}
          <span onDoubleClick={handleDoubleClick}>
            {authorInfo}
          </span>
        </Footer>
      </PageWrapper>
    </ThemeProvider>
  );
}

export default Index;