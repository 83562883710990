export const lightTheme = {
  // 操作按钮、标题，hover 后的高亮
  primaryColor: '#1da57a',
  // 副标题，时间，文章超链接
  secondaryColor: '#4d89b5',
  backgroundColor: '#ffffff',
  // 正文
  textColor: 'rgba(0, 0, 0, 0.65)',
  // 线条颜色：下划线，表格线
  lineColor: '#e8e8e8',

  // Highlight.js 相关颜色
  hljsBackgroundColor: '#f1f3f7',
  hljsCommentColor: '#aa5500',
  hljsKeywordColor: '#770088',
  hljsTitleColor: '#ca210b',
  hljsStringColor: '#d27676',
  hljsNameColor: '#4b63a2',
  hljsAttrColor: '#ab5683',
  hljsMetaColor: '#66a4c3',
  hljsBorder: 'none',

  // secondaryColor -> hover
  linkHoverColor: '#67a6d3',

  inlineCodeColor: '#ab5683',
  inlineCodeBackgroundColor: '#f1f3f7',

  contentHeaderColor: 'rgba(0, 0, 0, 0.7)',

  blockquoteBackgroundColor: '#eae7e7',
};

export const darkTheme = {
  isDark: true,

  primaryColor: '#539bf5',
  secondaryColor: '#64a0d7',
  // backgroundColor: '#22272e',
  backgroundColor: '#181818',
  textColor: '#adbac7',
  // 线条颜色：下划线，表格线
  lineColor: '#494949',

  // Highlight.js 相关颜色 (基于 GitHub 深色模式)
  hljsBackgroundColor: '#292e35',

  hljsColor: '#c9d1d9',
  hljsCommentColor: '#8b949e',
  hljsKeywordColor: '#ff7b72',
  hljsTitleColor: '#d2a8ff',
  hljsNameColor: '#7ee787',
  hljsAttrColor: '#79c0ff',
  hljsMetaColor: '#79c0ff',
  hljsBorder: '2px solid #30363d',
  hljsNumberColor: '#a5d6ff',
  hljsStringColor: '#a5d6ff',
  hljsTagColor: '#7ee787',

  blockquoteBackgroundColor: '#3a424d',

  linkHoverColor: '#93bde3',


  hoverColor: '#58a6ff',
};
