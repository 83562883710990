import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useTheme } from '../ThemeContext';

const TocWrapper = styled.div`
  position: relative;
  padding-left: 12px;
  margin-left: 12px;
`;

const TocList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const TocItem = styled.li`
  padding: 0 0;
  margin: 5px 0;
  &:first-child {
    padding-top: 0;
  }
`;

const TocLink = styled.a`
  color: ${props => props.theme.isDark ? 'rgba(255, 255, 255, 0.65)' : 'rgba(0, 0, 0, 0.65)'};
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;
  display: block;
  padding-left: ${props => (props.level - 1) * 12}px;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover, &.active {
    color: var(--primary-color);
  }
`;

const Indicator = styled.div`
  position: absolute;
  left: 0;
  width: 2px;
  background-color: ${props => props.theme.isDark ? '#4a4a4a' : '#eaecef'};
  top: 0;
  bottom: 0;
`;

const IndicatorBall = styled.div`
  position: absolute;
  left: -5px;
  width: 12px;
  height: 12px;
  background-color: var(--primary-color);
  border: 2px solid ${props => props.theme.isDark ? '#141414' : '#fff'};
  border-radius: 50%;
  transition: top 0.3s ease-in-out;
`;

const TableOfContent = () => {
  const { isDark } = useTheme();
  const [tocList, setTocList] = useState([]);
  const [activeId, setActiveId] = useState('');
  const indicatorRef = useRef(null);

  useEffect(() => {
    const generateTocList = () => {
      const content = document.getElementById('content');
      if (!content) return [];

      const headings = content.querySelectorAll('h1, h2, h3, h4, h5, h6');
      const tocItems = [];
      const levelStack = [{ level: 0, children: tocItems }];

      Array.from(headings).forEach((heading) => {
        const anchor = heading.querySelector('a');
        const level = parseInt(heading.tagName.charAt(1));
        const item = {
          id: anchor ? anchor.getAttribute('name') : '',
          title: heading.textContent,
          level: level,
          children: [],
        };

        while (level <= levelStack[levelStack.length - 1].level) {
          levelStack.pop();
        }
        levelStack[levelStack.length - 1].children.push(item);
        levelStack.push(item);
      });

      return tocItems;
    };

    const observer = new MutationObserver(() => {
      setTocList(generateTocList());
    });

    const content = document.getElementById('content');
    if (content) {
      observer.observe(content, { childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const flattenedTocList = flattenTocList(tocList);
      const headings = flattenedTocList.map(item => document.getElementById(item.id));
      const scrollPosition = window.scrollY;

      for (let i = headings.length - 1; i >= 0; i--) {
        if (headings[i] && headings[i].offsetTop <= scrollPosition + 100) {
          setActiveId(flattenedTocList[i].id);
          if (indicatorRef.current) {
            const activeElement = document.querySelector(`a[href="#${flattenedTocList[i].id}"]`);
            if (activeElement) {
              const linkHeight = activeElement.offsetHeight;
              const ballHeight = 12; // 圆点的高度
              indicatorRef.current.style.top = `${activeElement.offsetTop + (linkHeight - ballHeight) / 2}px`;
            }
          }
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [tocList]);

  const flattenTocList = (list) => {
    return list.reduce((acc, item) => {
      acc.push(item);
      if (item.children.length > 0) {
        acc.push(...flattenTocList(item.children));
      }
      return acc;
    }, []);
  };

  const handleClick = (e, href) => {
    e.preventDefault();
    const target = document.querySelector(href);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderTocItems = (items) => {
    return (
      <TocList>
        {items.map((item) => (
          <TocItem key={item.id}>
            <TocLink
              href={`#${item.id}`}
              onClick={(e) => handleClick(e, `#${item.id}`)}
              className={activeId === item.id ? 'active' : ''}
              theme={{ isDark }}
              level={item.level}
              title={item.title}
            >
              {item.title}
            </TocLink>
            {item.children.length > 0 && renderTocItems(item.children)}
          </TocItem>
        ))}
      </TocList>
    );
  };

  return (
    <TocWrapper theme={{ isDark }}>
      <Indicator theme={{ isDark }}>
        <IndicatorBall ref={indicatorRef} theme={{ isDark }} />
      </Indicator>
      {renderTocItems(tocList)}
    </TocWrapper>
  );
};

export default TableOfContent;